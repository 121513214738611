@import "fonts.css";
@import "inplayer.css";
@import "styleguide.css";
@import "ads.css";

@tailwind base;

html {
  @apply font-sans font-bold font-light text-gray-800
}

*:not(.outbrain-widget-wrapper *) {
  font-family: var(--sport1-regular), 'Sport1-Regular-Fallback';
}

@tailwind components;

@tailwind utilities;

.top-100 {
  top: 100%;
}

.video-aspect-ratio {
  aspect-ratio: 16 / 9;
}

strong {
  color: #000000;
  font-weight: bold;
}

ul {
  list-style-type: disc;
  padding-left: 40px;
}

ol {
  list-style-type: decimal;
  padding-left: 40px;
}

.fb-video,
.fb-video iframe[style],
.fb-post,
.fb-post span,
.fb-post span iframe[style] {
  width: 100% !important;
}

/* Piano Styles */
.tp-modal .tp-close {
  border-left-width: 0 !important;
}

.article-top-image-wrapper{
  aspect-ratio: 968/546 auto;
}

.piano-condensed-black-italic{
  font-family: 'Sport1-CondensedBlackItalic';
  font-variation-settings: 'ital' 1;
}

/**
 * For the purposes of stroeer, we have removed overflow:hidden from the articles and teaser card.
 * Most articles work without these properties, 
 * but sometimes ads, etc. are loaded from outside and this causes problems, so for safety, 
 * the max-width was used for article content
 * Sponsored articles don't have a right column
 */

 /* below 360px wide the layout starts to break down (this is a safety feature, beacuse devices with screens below 360px are rare) */
@media screen and (max-width: 359px) {
  .article-section-main-area-container,
  .article-section-main-area-container-sponsored{
    overflow: hidden;
  }
}
 /* MEW (phone) always can have max 100vw */
@media screen and (min-width: 360px) and (max-width: 1023px) {
  .article-section-main-area-container,
  .article-section-main-area-container-sponsored{
    max-width: 100%;
  }
}

/* MEW (tablet) It already has a column on the right side therefore, 36px (right margin) + 372px should be removed from the available space */
@media screen and (min-width: 1024px) and (max-width: 1112px) {
  .article-section-main-area-container{
    max-width: calc(100% - 408px);
  }
  .article-section-main-area-container-sponsored{
    max-width: 100%;
  }
}

/* WEB (desktop) It already has a column on the right side therefore, 36px (right margin) + 378px should be removed from the available space (1113px) */
@media screen and (min-width: 1113px) {
  .article-section-main-area-container{
    max-width: calc(1113px - 408px);
  }
  .article-section-main-area-container-sponsored{
    max-width: 1113px;
  }
}

/* It's a safety feature that prevents horizontal cards from taking up too much space */
.s1-teaser-card-content{
  max-width: 100%;
}

@media screen and (min-width: 1041px) {
  .s1-teaser-card-content{
    max-width: 1041px;
  }
}

.s1-device-mobile .apester-media,
.s1-device-mobile .apester-media-fallback {
  min-height: calc(470px + 367px);
}

.s1-device-desktop .apester-media,
.s1-device-desktop .apester-media-fallback {
  min-height: 483px;
}

#piano_article-bottom-bar{
  margin-bottom: env(safe-area-inset-bottom);
}

.article-footer-special-class {
  margin-bottom: calc(60px + env(safe-area-inset-bottom));
}

@media (min-width: 767px) {
  .article-footer-special-class {
      margin-bottom: calc(100px + env(safe-area-inset-bottom));
  }
}

@media (min-width: 480px) and (max-width: 766px) {
  .article-footer-special-class {
    margin-bottom: calc(80px + env(safe-area-inset-bottom));
  }
}